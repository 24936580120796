import logo from "./logo.webp"

const  clientData = {
   client_entity: 42,
   attorney_firm: 'elderbrinkmanlaw.com',
    attorney_name: 'Azzam Elder',
    attorney_number: '313-946-2000 & 800-My-Law-Firm',
    attorney_email: 'aelder@elderbrinkmanlaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_elder_brinkman_law+(720p)+(1).mp4',
    mainColor: '#000000',
    secondaryColor: '#1B75BB',
    siteLink: 'http://www.elderbrinkmanlaw.com',
    logo
}

export default clientData